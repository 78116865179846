import Logo from 'components/header/Logo/Logo'
import Navigation from 'components/header/Navigation/Navigation'
import styles from './Header.module.scss'

const Header = () => (
  <header className={styles.Header}>
    <div className={styles.Container}>
      <Logo />

      <Navigation
        links={[
          { label: 'Home', link: '/' },
          { label: 'About', link: '/about' }
        ]}
      />

      <Navigation links={[{ label: 'Sign Up', link: '/sign-up' }]} />
    </div>
  </header>
)

export default Header
