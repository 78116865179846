import { Link } from 'react-router-dom'

import logo from 'assets/svg/logo.svg'

const Logo = () => (
  <Link to="/">
    <img src={logo} alt="logo" />
  </Link>
)

export default Logo
