import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import styles from './Navigation.module.scss'

const Navigation = ({ links }) => (
  <nav className={styles.Navigation}>
    {links &&
      links.map(({ label, link }, index) => (
        <NavLink key={`nav-label-${index}`} to={link} activeClassName="active" exact>
          {label}
        </NavLink>
      ))}
  </nav>
)

Navigation.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object)
}

Navigation.defaultProps = {
  links: []
}

export default Navigation
