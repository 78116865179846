import PropTypes from 'prop-types'

import Header from 'modules/Header/Header'
import Footer from 'modules/Footer/Footer'

const Layout = ({ children }) => (
  <>
    <Header />
    <main>{children}</main>
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node
}

Layout.defaultProps = {
  children: null
}

export default Layout
