import { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

import Layout from 'pages/Layout'

const Home = lazy(() => import('modules/Home/Home'))
const About = lazy(() => import('modules/About/About'))
const SignUp = lazy(() => import('modules/SignUp/SignUp'))
const Account = lazy(() => import('modules/Account/Account'))

const Router = () => (
  <BrowserRouter>
    <Layout>
      <Suspense fallback={<div className="loading">Loading..</div>}>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/about" component={About} exact />
          <Route path="/sign-up" component={SignUp} exact />
          <Route path="/user-data" component={SignUp} exact />
          <Route path="/registered-success" component={SignUp} exact />
          <Route path="/account" component={Account} exact />
        </Switch>
      </Suspense>
    </Layout>
  </BrowserRouter>
)

export default Router
