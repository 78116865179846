import styles from './Footer.module.scss'

const Footer = () => (
  <footer className={styles.Footer}>
    <div className={styles.Container}>
      <p>© Demo page</p>
    </div>
  </footer>
)

export default Footer
